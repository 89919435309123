/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';
import {
  formatTimestamp,
  lastReportedTimestampFormat,
  lastUpdatedTimestampFormat,
  MILLISECONDS_PER_DAY
} from '../constants/index';
import _get from 'lodash/get';
import _max from 'lodash/max';
import _isEmpty from 'lodash/isEmpty';
import {isBaseTrailer, isCargoCameraTrailer, isGoTrailer, isSlapNTrackTrailer, isXT5300Trailer} from './sensorBoxMfgrs';
import sensorTypeIds from '../constants/enums/sensorTypeIds';

const browsersTimezone = moment.tz.guess();

export const formatDateTimeDefault = (time, fmt = formatTimestamp) => (time ? time.tz(browsersTimezone).format(fmt) : 'N/A');

export const getFormatDateTimeFunc = fmt => time => formatDateTimeDefault(time, fmt);

export const handleInvalidDate = dateStr => (!dateStr || dateStr === 'Invalid date' ? 'N/A' : dateStr);

export const getFormattedTimeAgo = milliseconds => {
  const {day, hour, minute} = getTimeAgo(milliseconds);
  const timeAgo = `${getValue(day, 'd')} ${getValue(hour, 'hr')} ${getValue(minute, 'm')}`;
  if (timeAgo.length > 1) {
    return `${timeAgo}`;
  }
  return timeAgo;
};

export const getFormattedUntetheredTimeElapsed = milliseconds => {
  const {day, hour, minute} = getTimeAgo(milliseconds);
  if (day >= 2) {
    return day + ' days untethered';
  } else if (day >= 1) {
    return '1 day untethered';
  } else if (hour >= 2) {
    return hour + ' hours untethered';
  } else if (hour >= 1) {
    return '1 hour untethered';
  } else if (minute >= 2) {
    return minute + ' minutes untethered';
  } else if (minute >= 1) {
    return '1 minute untethered';
  } else {
    return getFormattedTimeAgo(milliseconds);
  }
};
export const getLastReportTimeElapsed = milliseconds => {
  const {day, hour, minute, seconds} = getTimeAgo(milliseconds);

  if (day >= 2) {
    return day + ' days';
  } else if (day >= 1) {
    return '1 day';
  } else if (hour >= 2) {
    return hour + ' hours';
  } else if (hour >= 1) {
    return '1 hour';
  } else if (minute >= 2) {
    return minute + ' minutes';
  } else if (minute >= 1) {
    return '1 minute';
  } else if (minute < 1) {
    return seconds + ' seconds';
  } else {
    return getFormattedTimeAgo(milliseconds);
  }
};

export const getTimeAgo = milliseconds => {
  let day;
  let hour;
  let minute;
  let seconds;
  seconds = Math.floor(milliseconds / 1000);
  minute = Math.floor(seconds / 60);
  seconds %= 60;
  hour = Math.floor(minute / 60);
  minute %= 60;
  day = Math.floor(hour / 24);
  hour %= 24;

  return {day, hour, minute, seconds};
};

const getValue = (value, type) => {
  return value > 0 ? `${value}${type}` : ``;
};

export const getFormattedTimestamp = date => (date ? moment(date).tz(browsersTimezone).format(lastReportedTimestampFormat) : 'N/A');

export const displayUntetheredTime = tetherTimestamp => {
  if (!tetherTimestamp) {
    return '';
  }

  const untetheredSinceDate = getMsElapsedSince(tetherTimestamp);
  return ` - ${getLastReportTimeElapsed(untetheredSinceDate)}`;
};

const getMoreySensorTimestamps = vehicleDetails => {
  const installedSensorTypeIds = vehicleDetails?.installedSensorTypeIds ?? [];
  const sensorTimestamps = [
    {
      timestamp: _get(vehicleDetails, 'loadStatusDiagnostics.lastCommunicationAt', ''),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.enhancedWeight.key),
    },
    {
      timestamp: _get(vehicleDetails, 'odometerDiagnostics.absOdometerDiagnostic.lastCommunicationAt', ''),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.odometer.key),
    },
    {
      timestamp: _get(vehicleDetails, 'brakesDiagnostics.lastCommunicationAt', ''),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.absSystemFault.key),
    },
    {
      timestamp: _get(vehicleDetails, 'lightsDiagnostics.lightsDiagnosticsDetails.lastCommunicationAt', ''),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.lightSystemFault.key),
    },
    {
      timestamp: _get(vehicleDetails, 'tireInflationSystemDiagnostics.lastCommunicationAt', ''),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.tireInflationStatus.key),
    },
  ];

  return validatedSensorTimestamps(installedSensorTypeIds, sensorTimestamps);
};

const getXirgoSensorTimestamps = vehicleDetails => {
  const installedSensorTypeIds = vehicleDetails?.installedSensorTypeIds ?? [];
  const sensorTimestamps = [
    {
      timestamp: _get(vehicleDetails, 'gpsDiagnostics.lastCommunicationAt', ''),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.gps.key),
    },
    {
      timestamp: _get(vehicleDetails, 'fleetPulseDeviceDiagnostics.lastCommunicationAt', ''),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.gpsMileage.key),
    },
    {
      timestamp: _get(vehicleDetails, 'fleetPulseDeviceDiagnostics.lastCommunicationAt', ''),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.sensorBoxBattery.key),
    },
  ];

  return validatedSensorTimestamps(installedSensorTypeIds, sensorTimestamps);
};

const getTrackSensorTimestamps = vehicleDetails => {
  const installedSensorTypeIds = vehicleDetails?.installedSensorTypeIds ?? [];
  const sensorTimestamps = [
    {
      timestamp: _get(vehicleDetails, 'gpsDiagnostics.lastCommunicationAt', ''),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.gps.key),
    }
  ];
  return validatedSensorTimestamps(installedSensorTypeIds, sensorTimestamps)
}

const getCargoSensorTimestamps = (vehicleDetails) => {
  const installedSensorTypeIds = vehicleDetails?.installedSensorTypeIds ?? [];
  const sensorTimestamps = [
    {
      timestamp: _get(vehicleDetails, 'gpsDiagnostics.lastCommunicationAt', ''),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.gps.key),
    },
    {
      timestamp: _get(vehicleDetails, 'backupBatteryDiagnostics.lastCommunicationAt', ''),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.sensorBoxBattery.key)
    },
    {
      timestamp: _get(vehicleDetails, 'odometerDiagnostics.gpsOdometerDiagnostic.lastCommunicationAt', ''),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.gpsMileage.key),
    },
    {
      timestamp: _get(vehicleDetails, 'lastCargoCamera.recorded'),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.cargoCamera.key)
    }

  ];
  return validatedSensorTimestamps(installedSensorTypeIds, sensorTimestamps)
}


const getBaseTrailerSensorTimestamps = vehicleDetails => {
  const installedSensorTypeIds = vehicleDetails?.installedSensorTypeIds ?? [];
  const sensorTimestamps = [
    {
      timestamp: _get(vehicleDetails, 'gpsDiagnostics.lastCommunicationAt', ''),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.gps.key),
    },
    {
      timestamp: _get(vehicleDetails, 'tetherStatusDiagnostics.lastCommunicationAt', ''),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.tetherChange.key),
    },
    {
      timestamp: _get(vehicleDetails, 'odometerDiagnostics.gpsOdometerDiagnostic.lastCommunicationAt', ''),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.gpsMileage.key),
    },
    {
      timestamp: _get(vehicleDetails, 'backupBatteryDiagnostics.lastCommunicationAt', ''),
      sensorInstalled: sensorIsInstalled(installedSensorTypeIds, sensorTypeIds.backupBattery.key),
    },
  ];

  return validatedSensorTimestamps(installedSensorTypeIds, sensorTimestamps);
};

const validatedSensorTimestamps = (installedSensorTypeIds, sensorTimestamps) => {
  if (_isEmpty(installedSensorTypeIds)) {
    return (sensorTimestamps || []).map(sensorObj => sensorObj.timestamp);
  }

  return (sensorTimestamps || []).filter(sensor => sensor.sensorInstalled).map(sensorObj => sensorObj.timestamp);
};

export const sensorIsInstalled = (installedSensorTypeIds, key) =>
  _isEmpty(installedSensorTypeIds) ? true : (installedSensorTypeIds || []).includes(sensorTypeIds[key]?.sensor_type_id);

export const getSensorsMaxDate = (vehicleDetails) => {
  const deviceTypeShortName = vehicleDetails?.deviceTypeDetails?.description ?? null;
  if (isBaseTrailer(deviceTypeShortName)) {
    return _max(getBaseTrailerSensorTimestamps(vehicleDetails));
  }
  if (isGoTrailer(deviceTypeShortName)) {
    return _max(getXirgoSensorTimestamps(vehicleDetails));
  }
  if (isCargoCameraTrailer(deviceTypeShortName)) {
    return _max(getCargoSensorTimestamps(vehicleDetails))
  }
  if (isXT5300Trailer(deviceTypeShortName) || isSlapNTrackTrailer(deviceTypeShortName)) {
    return _max(getTrackSensorTimestamps(vehicleDetails))
  }
  return _max(getMoreySensorTimestamps(vehicleDetails)) ?? null;
};

export const getDateDifferenceByUnit = (timestamp, unit) => {
  if (!timestamp || !unit) {
    return 'N/A';
  }
  const currentDate = moment(new Date(), lastUpdatedTimestampFormat).tz(browsersTimezone);
  const date = moment(new Date(timestamp), lastUpdatedTimestampFormat).tz(browsersTimezone);

  return moment(currentDate).diff(moment(date), unit);
};

export const getMsElapsedSince = timestamp => getDateDifferenceByUnit(timestamp, 'milliseconds');

export const getTimeFromNowInDays = timestamp => getDateDifferenceByUnit(timestamp, 'days');

const getDateUnitText = (value, unit) => {
  if (value <= 0) {
    return '';
  }

  if (unit === 'days') {
    return value === 1 ? `${value} day` : `${value} days`;
  }

  if (unit === 'hours') {
    return value === 1 ? `${value} hour` : `${value} hours`;
  }

  if (unit === 'minutes') {
    return value === 1 ? `${value} minute` : `${value} minutes`;
  }

  if (unit === 'seconds') {
    return value === 1 ? `${value} second` : `${value} seconds`;
  }

  return '';
};

export const tetheredOrUntetheredSinceDetail = timestamp => {
  if (!timestamp) {
    return 'N/A';
  }

  let currentDate = moment().tz(browsersTimezone);
  const date = moment(timestamp).tz(browsersTimezone).format(lastUpdatedTimestampFormat);

  const days = moment(currentDate).diff(moment(date), 'days');
  const daysText = getDateUnitText(days, 'days');
  currentDate.subtract(days, 'days');

  const hours = moment(currentDate).diff(moment(date), 'hours');
  const hoursText = getDateUnitText(hours, 'hours');
  currentDate.subtract(hours, 'hours');

  const minutes = moment(currentDate).diff(moment(date), 'minutes');
  const minutesText = getDateUnitText(minutes, 'minutes');

  return `${daysText} ${hoursText} ${minutesText}`;
};

export const getMsElapsedFormattedTimeAgo = timestamp => {
  if (!timestamp) {
    return 'N/A';
  }

  const timeElapsedInMs = getMsElapsedSince(timestamp);

  if (timeElapsedInMs < 0) {
    return `Now`;
  }

  return `${getLastReportTimeElapsed(timeElapsedInMs)} ago`;
};

export const sensorHasReportedLast2Days = timestampMs => timestampMs <= 2 * MILLISECONDS_PER_DAY;

export const sensorHasReportedLast10Days = timestampMs => timestampMs <= 10 * MILLISECONDS_PER_DAY;

export const sensorHasReportedLast30Days = timestampMs => timestampMs <= 30 * MILLISECONDS_PER_DAY;

export const convertTimestampToTimeUnits = (value, unitToConvertFrom) => {
  const duration = moment.duration(value, unitToConvertFrom);
  const days = duration.days() !== 0 ? getDateUnitText(duration.days(), 'days') : '';
  const hours = duration.hours() ? getDateUnitText(duration.hours(), 'hours') : '';
  const minutes = duration.minutes() ? getDateUnitText(duration.minutes(), 'minutes') : '';
  const seconds = duration.seconds() ? getDateUnitText(duration.seconds(), 'seconds') : '';

  return `${days} ${hours} ${minutes} ${seconds}`;
};

export const daysToMilliseconds = days => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return days * millisecondsPerDay;
};

export const toJavaOffsetDateTime = date => {
  if (!(date instanceof Date)) return null;
  return moment(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
};

export const getNextDay = date => {
  if (!date) return null;
  let nextDay = new Date(date);
  nextDay.setDate(nextDay.getDate() + 1);
  return nextDay;
};

export const toISOWithoutTimezoneAdjustment = (date) => {
  if(!date) return null;
  return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
}

export const trackingRecordedDateFormatted = (date, browsersTimezone) => moment(date).tz(browsersTimezone).format('M/D/YY');
export const trackingRecordedTimestampFormatted = (time, browsersTimezone) => moment(time).tz(browsersTimezone).format('h:mm A z');
export const trackingHistoryAddress = (reverseGeoFullAddress, latitude, longitude) =>
  reverseGeoFullAddress !== null ? reverseGeoFullAddress : latitude + ', ' + longitude;